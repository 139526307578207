import React from 'react';
import { SummaryList } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import fileDownload from 'js-file-download';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { downloadFiles } from '../../firebase';

const ChargepointDetails = ({ claimData }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const chargepointsData =
    claimData.grant_type === 'Renters and flat owners'
      ? [claimData.chargepoints_details]
      : claimData.chargepoints_details;

  const chargepointsEvidence = chargepointsData.map((c) => c.evidence);

  const getPhotosNames = (photos) => {
    return photos.map((photo) => {
      if (claimData.grant_type === 'Renters and flat owners') {
        return <section key={photo.image_id}> {photo.image_id}</section>;
      }
      return <section key={photo.image}> {photo.image}</section>;
    });
  };

  const chargePointEvidenceDownload =
    chargepointsEvidence.length > 0
      ? chargepointsEvidence[0].map((e) => {
          if (claimData.grant_type === 'Renters and flat owners') {
            return {
              imageName: e.image_id,
              imagePath: e.image,
            };
          }
          return {
            imageName: e.image,
            imagePath: e.image,
          };
        })
      : [];

  const handleClick = async (data) => {
    const response = await downloadFiles({
      download: data,
    });

    response.data.filesURL.forEach((file) => {
      axios
        .get(file.imageURL, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, file.imageName);
        })
        .catch(() => {
          // TODO:
        });
    });
  };

  const chargepointRows = chargepointsData.map((chargepointDetails, index) => {
    return {
      key: {
        children: (
          <>
            {copyProvider.getCopy('Installer.Resubmit.s2', 'Chargepoint ')}
            {index + 1}
          </>
        ),
      },
      value: {
        children: [
          <>
            {/* Manufacturer */}
            <strong key="0">
              {copyProvider.getCopy(
                'Installer.Resubmit.ChargepointDetails.s3',
                'Manufacturer: '
              )}
            </strong>
            {chargepointDetails.chargepoint_manufacturer}
            <br />
            {/* Model */}
            <strong key="1">
              {copyProvider.getCopy(
                'Installer.Resubmit.ChargepointDetails.s4',
                'Model: '
              )}
            </strong>
            {chargepointDetails.chargepoint_model}
            <br />
            {/* Chargepoint sockets */}
            <strong key="2">
              {copyProvider.getCopy(
                'Installer.Resubmit.ChargepointDetails.s5',
                'Chargepoint sockets: '
              )}
            </strong>
            {chargepointDetails.number_of_sockets}
            <br />
            {/* Serial number */}
            <strong key="3">
              {copyProvider.getCopy(
                'Installer.Resubmit.ChargepointDetails.s6',
                'Serial number: '
              )}
            </strong>
            {chargepointDetails.serial_number}
            <br />
            {/* Photos */}
            <strong key="4">
              {copyProvider.getCopy(
                'Installer.Resubmit.ChargepointDetails.s7',
                'Photos: '
              )}
            </strong>
            {getPhotosNames(chargepointDetails.evidence)}
            <Link
              to={{}}
              onClick={() => handleClick(chargePointEvidenceDownload)}
            >
              {copyProvider.getCopy(
                'Installer.Resubmit.ChargepointDetails.s8',
                'Download photos'
              )}
            </Link>
          </>,
        ],

        format: 'text',
      },
    };
  });

  return (
    <>
      <h1 className="govuk-heading-m govuk-!-margin-top-5">
        {copyProvider.getCopy(
          'Installer.Resubmit.ChargepointDetails.s1',
          'Chargepoint details'
        )}
      </h1>
      <SummaryList
        className="govuk-heading-m govuk-!-margin-bottom-9"
        rows={chargepointRows}
      />
    </>
  );
};
ChargepointDetails.propTypes = {
  claimData: PropTypes.object.isRequired,
};
export default ChargepointDetails;
