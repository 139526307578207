import React, { useState } from 'react';
import { Button, Input, ErrorSummary } from 'govuk-react-jsx';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getClaimsByInstallerId,
  getInstallerDetails,
  signInUser,
  getAccount,
  logLoginAttempts,
} from '../../firebase';

import {
  getCurrentLanguage,
  setCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setUserLockedStatus,
} from '../../features/app/appSlice';
import { setIsResidential } from '../../features/customer/customerJourneySlice';
import {
  setClientCount,
  setUserAccountInfo,
  setUserDetails,
} from '../../features/dashboard/dashboardSlice';
import convertFirebaseTimestampToDate from '../../utils/convertFirebaseTimestampToDate';
import useTitle from '../../hooks/useTitle';

const SignIn = () => {
  const [errorList, setErrorList] = useState([]);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorPassword, setErrorPassword] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useTitle(
    'SMEReg.Signin.s17',
    'Sign in or create an account - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const MAX_FAILED_ATTEMPTS = 5;

  const INSTALLER_ACCOUNT_TYPE = 'installer';
  document.title =
    'Sign in or create an account - OZEV Electric Vehicle Chargepoint Grant - GOV.UK';

  const blankEmail = {
    children: copyProvider.getCopy('Signin.s11', 'Enter an email address'),
  };

  const blankPassword = {
    children: copyProvider.getCopy('Signin.s12', 'Enter a password'),
  };

  const invalidCredentials = {
    children: copyProvider.getCopy(
      'Signin.s13',
      'Your login details are incorrect'
    ),
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLinkClick = () => {
    history.push('/forgotten-password');
  };

  const getInstaller = () => {
    getInstallerDetails()
      .then((response) => {
        if (response.data.success) {
          if (
            response.data.account_type.toLowerCase() === INSTALLER_ACCOUNT_TYPE
          ) {
            getClaimsByInstallerId({ searchTerm: '' })
              .then((res) => {
                if (res.data.success && res.data.totalClaims > 0) {
                  dispatch(setIsResidential(response.data.isResidential));
                  history.push('/installer/claim-grant/installer-dashboard');
                } else {
                  history.push(
                    '/installer/claim-grant/installer-dashboard-first-time'
                  );
                }
              })
              .catch(() => {
                // TODO: error handling later
              });
          }
        }
      })
      .catch(() => {
        // Todo later error handling
      });
  };
  const handleSignin = (event) => {
    const errorListArr = [];
    event.preventDefault();
    let hasEmailError = false;
    let hasPasswordError = false;
    if (email === '') {
      setErrorEmail(blankEmail);
      errorListArr.push({ ...blankEmail, href: '#email' });
      hasEmailError = true;
    } else {
      setErrorEmail(null);
      hasEmailError = false;
    }
    if (password === '') {
      setErrorPassword(blankPassword);
      errorListArr.push({
        ...blankPassword,
        href: '#password',
      });
      hasPasswordError = true;
    } else {
      setErrorPassword(null);
      hasPasswordError = false;
    }
    if (!hasEmailError && !hasPasswordError) {
      signInUser(email, password)
        .then(() => {
          dispatch(setUserAccountInfo(null));
          logLoginAttempts({ email, valid_attempt: true }).then(
            (validResponse) => {
              const currentTimestamp = new Date();
              if (
                validResponse.data.locked_account_data.failed_login_count <
                  MAX_FAILED_ATTEMPTS ||
                validResponse.data.locked_account_data
                  .release_lockout_timestamp === null ||
                currentTimestamp >
                  convertFirebaseTimestampToDate(
                    validResponse.data.locked_account_data
                      .release_lockout_timestamp
                  )
              ) {
                getAccount()
                  .then((accountResponse) => {
                    const { data } = accountResponse;
                    if (data.account_type === 'Installer') {
                      getInstaller();
                    } else if (data.account_type === 'Landlord') {
                      history.push('/dashboardHub/dashboard');
                    } else if (
                      data.account_type === 'Property Management Company'
                    ) {
                      if (
                        accountResponse.data.pmc_client &&
                        accountResponse.data.pmc_client.length > 0
                      ) {
                        dispatch(
                          setClientCount(accountResponse.data.pmc_client.length)
                        );
                      }
                      history.push('/dashboardHub/dashboard-pmc');
                    } else if (
                      data.account_type === 'Small to medium enterprise'
                    ) {
                      dispatch(setUserDetails(data));
                      if (data?.carpark_comm_claim_count) {
                        history.push('/sme-app/dashboard2');
                      } else {
                        history.push('/sme-app/dashboard-first-time');
                      }
                    }
                  })
                  .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.debug('Get Account error:', err.message);
                    throw new Error(err);
                  });
              } else {
                dispatch(setUserLockedStatus(true));
                history.push('/account-locked');
              }
            }
          );
        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            setErrorList([{ ...invalidCredentials, href: '#email' }]);
            setErrorEmail(invalidCredentials);
            setErrorPassword(invalidCredentials);
            setEmail('');
            setPassword('');
          }
          if (error.code === 'auth/wrong-password') {
            setErrorList([{ ...invalidCredentials, href: '#email' }]);
            setErrorEmail(invalidCredentials);
            setErrorPassword(invalidCredentials);
            setEmail('');
            setPassword('');
            logLoginAttempts({ email, valid_attempt: false }).then(
              (response) => {
                if (
                  response.data.locked_account_data.failed_login_count >=
                  MAX_FAILED_ATTEMPTS
                ) {
                  history.push('/account-locked');
                }
              }
            );
          }
        });
    } else {
      setErrorList(errorListArr);
    }
  };

  const signinPage = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <div className="govuk-grid-column-width-full govuk-!-padding-left-4">
        <h1 className="govuk-heading-xl govuk-!-width-full app-padding-left-signin">
          {copyProvider.getCopy('Signin.s1', 'Sign in or create an account')}
        </h1>
        <div className="govuk-grid-column-one-half app-padding-left-signin">
          <h2 className="govuk-heading-m">
            {copyProvider.getCopy('Signin.s2', 'Sign in')}
          </h2>
          <p className="govuk-body-m">
            {copyProvider.getCopy(
              'Signin.s10',
              'If you already have an account, sign in.'
            )}
          </p>
          <div className="form-group govuk-!-margin-top-7">
            <Input
              className="govuk-label govuk-!-width-three-quarters"
              errorMessage={errorEmail}
              label={{
                children: copyProvider.getCopy('Signin.s4', 'Email address'),
              }}
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(event) => handleEmail(event)}
            />
          </div>
          {/* Password */}
          <div className="form-group">
            <Input
              className="govuk-label govuk-!-width-three-quarters"
              errorMessage={errorPassword}
              label={{
                children: copyProvider.getCopy('Signin.s5', 'Password'),
              }}
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(event) => handlePassword(event)}
            />
          </div>
          <Button
            className="govuk-button"
            data-module="govuk-button"
            onClick={handleSignin}
          >
            {copyProvider.getCopy('Signin.s2', 'Sign in')}
          </Button>
        </div>
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-one-half">
            <h2 className="govuk-heading-m">
              {copyProvider.getCopy('Signin.s3', 'Create an account')}
            </h2>
            <p className="govuk-body">
              {copyProvider.getCopy(
                'Signin.s8',
                'To use this service you first need to create an account.'
              )}
            </p>
            <Button
              className="govuk-button"
              data-module="govuk-button"
              onClick={() => history.push('/account-type')}
            >
              {copyProvider.getCopy('Signin.s3', 'Create an account')}
            </Button>
          </div>
        </div>
        {/* Need Help */}
        <div className="form-group govuk-!-margin-top-4">
          <h2 className="govuk-heading-m">
            {copyProvider.getCopy('Signin.s7', 'Need help signing in?')}
          </h2>
          <Link
            className="govuk-link govuk-body-m"
            to={{}}
            onClick={handleLinkClick}
          >
            {copyProvider.getCopy('Signin.s9', 'I have forgotten my password')}
          </Link>
          <div className="govuk-body">
            <p>
              {copyProvider.getCopy(
                'Signin.s15',
                'This service is also available in Welsh '
              )}
              <Link
                className="govuk-link"
                onClick={() =>
                  dispatch(
                    setCurrentLanguage(currentLanguage === 'en' ? 'cy' : 'en')
                  )
                }
                to={{}}
              >
                <span lang={currentLanguage === 'en' ? 'cy' : 'en'}>
                  {copyProvider.getCopy('Signin.s16', '(Cymraeg)')}
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );

  return <div>{signinPage}</div>;
};

export default SignIn;
