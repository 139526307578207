import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Confirmation } from '@pepper-ui/common';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import useGaEndEvent from '../../hooks/useGaEndEvent';

const AccountCreated = () => {
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  // useGaEndEvent('LandlordRegistration', 'reg_account_created');
  useTitle(
    'SMEReg.AccountCreated.s8',
    'Account created - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  useGaEndEvent('SmeRegistration', 'sme_reg_account_created');
  const handleBtnClick = () => {
    history.push('/signin');
  };

  return (
    <Confirmation
      panel={copyProvider.getCopy(
        'SMEReg.AccountCreated.s1',
        'Account created'
      )}
      body={{
        paragraph1: copyProvider.getCopy(
          'SMEReg.AccountCreated.s2',
          'We have sent you an email to confirm your account has been created.'
        ),
        paragraph2: copyProvider.getCopy(
          'SMEReg.AccountCreated.s3',
          'You can now use your email and password to sign in to your account and apply for the grant.'
        ),
      }}
      heading2={copyProvider.getCopy(
        'SMEReg.AccountCreated.s4',
        'What happens next'
      )}
      anchor={{
        text1: copyProvider.getCopy(
          'SMEReg.AccountCreated.s5',
          'What did you think of this service?'
        ),
        text2: copyProvider.getCopy(
          'SMEReg.AccountCreated.s6',
          '(takes 30seconds)'
        ),
      }}
      button={{
        text: copyProvider.getCopy(
          'SMEReg.AccountCreated.s7',
          'Sign in to your account'
        ),
        onClick: handleBtnClick,
      }}
    />
  );
};

export default AccountCreated;
