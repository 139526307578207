import React from 'react';
import { SummaryList } from 'govuk-react-jsx';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import convertDateToDisplay from '../../utils/convertDateToDisplay';

const InstallationDetails = ({ claimData }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const whoCompletedInstallation = () => {
    if (
      claimData.installation_done_by === 'employee' ||
      claimData.subcontractor_id === ''
    ) {
      return (
        <>
          <section>{claimData.installer_company_name}</section>
          <section>{claimData.installer_id}</section>
        </>
      );
    }
    // If installer is Subcontractor
    return (
      <>
        <section>
          {copyProvider.getCopy(
            'Installer.Resubmit.InstallationDetails.s5',
            'Subcontractor'
          )}
        </section>
        <section>{claimData.subcontractor_company_name}</section>
        <section>{claimData.subcontractor_id}</section>
      </>
    );
  };

  const address = (
    <>
      <section>
        {claimData.addressLine1 && `${claimData.addressLine1},`}
      </section>
      <section>
        {claimData.addressLine2 && `${claimData.addressLine2},`}
      </section>
      <section>{claimData.city && `${claimData.city},`}</section>
      <section>{claimData.county && `${claimData.county},`}</section>
      <section>{claimData.postCode && claimData.postCode}</section>
    </>
  );

  const numberOfChargepoints = () => {
    if (claimData.grant_type !== 'Renters and flat owners') {
      return copyProvider.getCopy(
        'Installer.Resubmit.InstallationDetails.s6',
        'Number of chargepoints'
      );
    }
    return '';
  };

  const numberOfChargepointSockets = () => {
    if (claimData.grant_type !== 'Renters and flat owners') {
      return copyProvider.getCopy(
        'Installer.Resubmit.InstallationDetails.s7',
        'Number of chargepoint sockets'
      );
    }
    return '';
  };

  return (
    <>
      <h1 className="govuk-heading-m govuk-!-margin-top-5">
        {copyProvider.getCopy(
          'Installer.Resubmit.InstallationDetails.s1',
          'Installation details'
        )}
      </h1>

      <SummaryList
        className="govuk-!-margin-bottom-9"
        rows={[
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s2',
                'Address'
              ),
            },
            value: {
              children: address,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s3',
                'Who completed the installation'
              ),
            },
            value: {
              children: whoCompletedInstallation(),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.InstallationDetails.s4',
                'Date installations were completed'
              ),
            },
            value: {
              children: convertDateToDisplay(
                claimData.installation_completed_date
              ),
            },
          },
          numberOfChargepoints &&
            claimData.number_of_chargepoints_applied && {
              key: {
                children: numberOfChargepoints(),
              },
              value: {
                children: claimData.number_of_chargepoints_applied,
              },
            },
          numberOfChargepointSockets &&
            claimData.number_of_chargepoint_sockets_applied && {
              key: {
                children: numberOfChargepointSockets(),
              },
              value: {
                children: claimData.number_of_chargepoint_sockets_applied,
              },
            },
        ]}
      />
    </>
  );
};

InstallationDetails.propTypes = {
  claimData: PropTypes.object.isRequired,
};
export default InstallationDetails;
