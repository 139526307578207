import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Input, ErrorSummary } from 'govuk-react-jsx';
import { useHistory, Link } from 'react-router-dom';
import { getClaimsByInstallerId } from '../../../firebase';
import { checkSearchTerm } from '../../../regex/regex';

import {
  getClaimsData,
  setClaimsData,
  getIsResidential,
} from '../../../features/customer/customerJourneySlice';
import displayMenu from '../../../hooks/displayMenu';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
  setDisplayGoBackLink,
} from '../../../features/app/appSlice';

import ClaimStatusClassName from '../../common/ClaimStatus';
import convertDateToDisplay from '../../../utils/convertDateToDisplay';
import useTitle from '../../../hooks/useTitle';

const InstallerDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'Installer.Dashboard.InstallerDashboard.s29',
    'Manage your claims - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );
  const claimsDataValue = useSelector(getClaimsData);
  const isResidential = useSelector(getIsResidential);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchHasResultNo, setSearchHasNoResult] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [errorList, setErrorList] = useState([]);

  const mapDashboardStatuses = {
    'READY TO CLAIM': 'READY TO CLAIM',
    'SUBMITTED FOR REVIEW': 'SUBMITTED FOR REVIEW',
    'APPROVED FOR PAYMENT': 'APPROVED FOR PAYMENT',
    DECLINED: 'DECLINED',
    CANCELLED: 'CANCELLED',
    EXPIRED: 'EXPIRED',
    'READY FOR INSTALLER CLAIM': 'READY TO CLAIM',
    'INSTALLER CLAIM INCOMPLETE': 'INCOMPLETE',
    'INSTALLER CLAIMED': 'SUBMITTED FOR REVIEW',
    PAYMENT: 'APPROVED FOR PAYMENT',
    APPROVED: 'APPROVED FOR PAYMENT',
    'Installer Claimed': 'SUBMITTED FOR REVIEW',
    REJECTED: 'REJECTED',
    RESUBMITTED: 'SUBMITTED FOR REVIEW',
    'SEND TO DYNAMICS': 'SUBMITTED FOR REVIEW',
    'EXPIRING SOON': 'EXPIRING SOON',
  };

  const searchTermErrorMsg = copyProvider.getCopy(
    'Installer.Dashboard.InstallerDashboard.s17',
    'Enter 3 or more characters'
  );

  const tableHead = [
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s5',
        'Reference number'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s6',
        'Grant type'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s7',
        'Customer'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s8',
        'Postcode'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s9',
        'Claim status'
      ),
    },
    {
      children: copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s10',
        'Action'
      ),
    },
  ];

  const setLinkText = (claimStatus, claimSubmissionDeadlineDate) => {
    if (
      claimStatus === 'READY TO CLAIM' ||
      claimStatus === 'READY FOR INSTALLER CLAIM' ||
      claimStatus === 'INSTALLER CLAIM INCOMPLETE'
    ) {
      return (
        <span>
          {copyProvider.getCopy(
            'Installer.Dashboard.InstallerDashboard.s20',
            'Submit claim by '
          )}
          {claimSubmissionDeadlineDate}
        </span>
      );
    }
    if (
      claimStatus === 'SUBMITTED FOR REVIEW' ||
      claimStatus === 'APPROVED FOR PAYMENT' ||
      claimStatus === 'EXPIRED' ||
      claimStatus === 'CANCELLED' ||
      claimStatus === 'PAYMENT' ||
      claimStatus === 'SEND TO INSTALLER' ||
      claimStatus === 'INSTALLER CLAIMED' ||
      claimStatus === 'RESUBMITTED' ||
      claimStatus === 'SEND TO DYNAMICS'
    ) {
      return copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s21',
        'View claim '
      );
    }
    if (claimStatus === 'DECLINED') {
      return copyProvider.getCopy(
        'Installer.Dashboard.InstallerDashboard.s22',
        'View declined claim'
      );
    }
    if (claimStatus === 'REJECTED') {
      return (
        <span>
          {copyProvider.getCopy(
            'Installer.Dashboard.InstallerDashboard.s23',
            'Resubmit claim by '
          )}
          {claimSubmissionDeadlineDate}
        </span>
      );
    }

    if (claimStatus === 'EXPIRING SOON') {
      return (
        <span>
          {copyProvider.getCopy(
            'Installer.Dashboard.InstallerDashboard.s30',
            'Renew by '
          )}
          {claimSubmissionDeadlineDate}
        </span>
      );
    }

    return '';
  };

  const setToLink = (claimStatus, grantType) => {
    let linkDirectedPage;
    if (grantType === 'Renters and flat owners') {
      linkDirectedPage = {
        'READY TO CLAIM':
          '/evhs/installer/claim-grant/review-customer-application',
        'SUBMITTED FOR REVIEW':
          '/evhs/installer/claim-grant/view-submitted-claim',
        'APPROVED FOR PAYMENT':
          '/evhs/installer/claim-grant/view-approved-claim',
        PAYMENT: '/evhs/installer/claim-grant/view-approved-claim',
        DECLINED: '/evhs/installer/claim-grant/view-declined-claim',
        REJECTED: '/resubmit-journey/resubmit-claim',
        'EXPIRING SOON': '/application-renewed',
      };
    }
    if (
      grantType === 'Residential landlord' ||
      grantType === 'Commercial landlord'
    ) {
      linkDirectedPage = {
        'INSTALLER CLAIM INCOMPLETE':
          '/evcg/dashboardHub/view-grant-application',
        'READY FOR INSTALLER CLAIM':
          '/evcg/dashboardHub/view-grant-application',
        RESUBMITTED:
          '/evcg/dashboardHub/view-application-submitted-by-installer',
        'APPROVED FOR PAYMENT': '/evcg/dashboardHub/approved-for-payment',
        DECLINED: '/evcg/dashboardHub/declined',
        PAYMENT: '/evcg/dashboardHub/approved-for-payment',
        'SUBMITTED BY INSTALLER':
          '/evcg/dashboardHub/view-application-submitted-by-installer',
        'SEND TO INSTALLER':
          '/evcg/dashboardHub/view-application-sent-to-installer',
        'SUBMITTED FOR REVIEW': '/evcg/dashboardHub/submitted-for-review',
        REJECTED: '/resubmit-journey/resubmit-claim',
        'INSTALLER CLAIMED': '/evcg/dashboardHub/submitted-for-review',
        'EXPIRING SOON': '/application-renewed',
        EXPIRED: '/landlord-application/application-expired-residential',
      };
    }
    if (grantType === 'Residential car park') {
      linkDirectedPage = {
        'READY TO CLAIM': '/car-parks/installer-2/check-customer-application',
        'INSTALLER CLAIM INCOMPLETE':
          '/car-parks/installer-2/check-customer-application',
        'SUBMITTED FOR REVIEW': '/car-parks/installer-2/submitted-for-review',
        DECLINED: '/car-parks/installer-2/declined',
        'APPROVED FOR PAYMENT': '/car-parks/installer-2/approved-for-payment',
        'EXPIRING SOON': '/application-renewed',
        EXPIRED: '/landlord-application/application-expired-residential',
        REJECTED: '/resubmit-journey/resubmit-claim',
      };
    }

    if (grantType === 'Staff and fleet car park') {
      linkDirectedPage = {
        'READY TO CLAIM':
          '/car-parks/installer-2/sme/check-customer-application',
        'INSTALLER CLAIM INCOMPLETE':
          '/car-parks/installer-2/sme/check-customer-application',
        'SUBMITTED FOR REVIEW':
          '/car-parks/installer-2/sme/submitted-for-review',
        DECLINED: '/car-parks/installer-2/sme/declined',
        'APPROVED FOR PAYMENT':
          '/car-parks/installer-2/sme/approved-for-payment',
        'EXPIRING SOON': '/application-renewed',
        EXPIRED: '/landlord-application/application-expired-residential',
        REJECTED: '/resubmit-journey/resubmit-claim',
      };
    }
    return linkDirectedPage[claimStatus];
  };

  const populateTable = () => {
    return (
      claimsDataValue &&
      claimsDataValue.length > 0 &&
      claimsDataValue.map((customer) => {
        return {
          cells: [
            {
              children: customer.customerReferenceNumber,
            },
            {
              children: customer.grant_type,
            },
            {
              children: `${customer.firstName} ${customer.lastName}`,
            },
            {
              children: customer.postCode,
            },
            {
              children: ClaimStatusClassName(
                mapDashboardStatuses[customer.claimStatus.toUpperCase()]
              ),
            },
            {
              children: (
                <Link
                  to={{}}
                  onClick={() =>
                    customer.claimStatus.toUpperCase() === 'EXPIRING SOON'
                      ? window.location.assign(`
                    ${setToLink(
                      customer.claimStatus.toUpperCase(),
                      customer.grant_type
                    )}/${customer.grant_type}/${customer.document_id}`)
                      : window.location.assign(`
                      ${setToLink(
                        customer.claimStatus.toUpperCase(),
                        customer.grant_type
                      )}/${customer.document_id}`)
                  }
                >
                  {setLinkText(
                    customer.claimStatus.toUpperCase(),
                    convertDateToDisplay(
                      customer.claim_submission_deadline_date
                    )
                  )}
                </Link>
              ),
            },
          ],
        };
      })
    );
  };

  displayMenu();

  useEffect(() => {
    dispatch(setDisplayGoBackLink(false));
    getClaimsByInstallerId({ searchTerm: '' })
      .then((response) => {
        if (response.data.success) {
          dispatch(setClaimsData(response.data.claims));
        }
      })
      .catch(() => {
        history.push('/installer/claim-grant/installer-dashboard-first-time');
      });
    return () => dispatch(setDisplayGoBackLink(true));
  }, []);

  const handleAddCustomerDetailsSubmit = () => {
    window.location.assign(
      '/evhs/installer/application-setup/customer-details'
    );
  };

  const handleSearch = (event) => {
    event.preventDefault();
    let hasError = false;
    if (!checkSearchTerm.test(searchTerm)) {
      const searchTermError = {
        children: searchTermErrorMsg,
      };
      setErrorMsg(searchTermError);
      setErrorList([{ ...searchTermError, href: '#search' }]);
      hasError = true;
      return;
    }
    setErrorList([]);
    setErrorMsg(null);
    if (!hasError) {
      getClaimsByInstallerId({
        searchTerm: searchTerm.toLowerCase(),
      })
        .then(({ data: { claims, success } }) => {
          if (success) {
            dispatch(setClaimsData(claims));
            if (!claims.length) {
              setSearchHasNoResult(true);
            } else {
              setSearchPerformed(true);
              setSearchHasNoResult(false);
            }
          }
        })
        .catch(() => {
          setSearchHasNoResult(true);
        });
    }
  };
  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      {isResidential && (
        <>
          <div className="govuk-grid-row">
            <div div className="govuk-grid-column-two-third">
              <h1 className="govuk-heading-xl">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s1',
                  'Manage your claims'
                )}
              </h1>
            </div>
          </div>

          <div className="govuk-grid-row">
            <div div className="govuk-grid-column-two-third">
              <p className="govuk-body">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s2',
                  'To begin a new claim for the'
                )}{' '}
                <b>
                  {copyProvider.getCopy(
                    'Installer.Dashboard.InstallerDashboard.s3',
                    'renters and flat owners grant'
                  )}
                </b>
                <br />
                <Link to={{}} onClick={handleAddCustomerDetailsSubmit}>
                  {copyProvider.getCopy(
                    'Installer.Dashboard.InstallerDashboard.s4',
                    "enter your customer's details."
                  )}
                </Link>
              </p>
            </div>
            <br />
            <hr className="govuk-section-break govuk-section-break--l govuk-section-break--visible" />
          </div>
        </>
      )}
      <div className="govuk-grid-row govuk-!-margin-top-4">
        <div className="moj-search">
          <form action="#" method="post">
            <Input
              className="govuk-input moj-search__input"
              id="search"
              hint={{
                children: copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s11',
                  'You can search using the customer name, postcode and reference number'
                ),
              }}
              label={{
                children: (
                  <h2 className="govuk-heading-m">
                    {copyProvider.getCopy(
                      'Installer.Dashboard.InstallerDashboard.s12',
                      'Find a claim'
                    )}
                  </h2>
                ),
              }}
              value={searchTerm}
              errorMessage={errorMsg}
              name="search"
              type="search"
              onChange={onSearchChange}
            />
            <Button
              className="moj-search__button"
              data-module="govuk-button"
              onClick={handleSearch}
            >
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s13',
                'Search'
              )}
            </Button>
          </form>
        </div>
      </div>
      {searchHasResultNo && (
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-one-half">
            <h3 className="govuk-heading-s">
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s14',
                'There are no matching results'
              )}
            </h3>
            <p className="govuk-body">
              {copyProvider.getCopy(
                'Installer.Dashboard.InstallerDashboard.s18',
                'Improve your search results by:'
              )}
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s15',
                  'double-checking your spelling'
                )}
              </li>
              <li>
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s16',
                  'using few keywords'
                )}
              </li>
            </ul>
            <a
              href="/installer/claim-grant/installer-dashboard"
              className="govuk-link "
            >
              <span role="presentation">
                {copyProvider.getCopy(
                  'Installer.Dashboard.InstallerDashboard.s19',
                  'Clear search'
                )}
              </span>
            </a>
          </div>
        </div>
      )}
      {searchPerformed && !searchHasResultNo && (
        <div className="govuk-grid-row">
          <Table head={tableHead} rows={populateTable()} />
          <a
            href="/installer/claim-grant/installer-dashboard"
            className="govuk-link "
          >
            <span role="presentation">
              {copyProvider.getCopy(
                '.ClaimGrant.InstallerDashboard.s19',
                'Clear search'
              )}
            </span>
          </a>
        </div>
      )}
      {!searchPerformed && !searchHasResultNo && (
        <div className="govuk-grid-row">
          <Table head={tableHead} rows={populateTable()} />
        </div>
      )}
    </>
  );
};
export default InstallerDashboard;
