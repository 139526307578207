/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parkingTypesSelected: [],
  buildingTypeSelected: '',
  installerOzevNumber: '',
  propertyTypeSelected: '',
  currentlyAvailableGrants: [],
  currentlyUnavailableGrants: [],
  staffFleetChargepoints: '',
  installationAddress: {
    buildingAndStreet: '',
    buildingAndStreet2: '',
    townOrCity: '',
    county: '',
    postCode: '',
  },
  installerDetails: {
    installer_number: '',
    installer_name: '',
    registered_office_address: {
      address_line_1: 'Westside Tower 7 City Road',
      region: 'London',
      country: 'England',
      postal_code: 'WS16 NWR',
    },
  },
  numberOfChargepointsInstalled: '',
  numberOfChargepointsSocketsInstalled: '',
  // Data for T518
  landlordOrPmcPrivateParking: '',
  // Data for T222
  grantTypeSelected: '',
  grantTypeValue: '',
  applicantAnnualQuota: '',
  applicationDocumentId: '',

  subcontractor_id: '',
  subcontractor_company_name_upper: '',
  subcontractor_first_name: '',
  subcontractor_last_name: '',
  subcontractor_contact_email: '',
};

export const applicationJourneySlice = createSlice({
  name: 'applicationJourney',
  initialState,
  reducers: {
    setSubcontractorId: (state, action) => {
      state.subcontractor_id = action.payload;
    },
    setSubcontractorCompanyName: (state, action) => {
      state.subcontractor_company_name_upper = action.payload;
    },
    setSubcontractorFirstName: (state, action) => {
      state.subcontractor_first_name = action.payload;
    },
    setSubcontractorLastName: (state, action) => {
      state.subcontractor_last_name = action.payload;
    },
    setSubcontractorEmail: (state, action) => {
      state.subcontractor_contact_email = action.payload;
    },

    setPropertyTypeSelected: (state, action) => {
      state.propertyTypeSelected = action.payload;
    },
    setBuildingTypeSelected: (state, action) => {
      state.buildingTypeSelected = action.payload;
    },
    setStaffFleetChargepoints: (state, action) => {
      state.staffFleetChargepoints = action.payload;
    },
    setInstallerOzevNumber: (state, action) => {
      state.installerOzevNumber = action.payload;
    },
    setInstallationAddressBuildingAndStreet: (state, action) => {
      state.installationAddress.buildingAndStreet = action.payload;
    },
    setInstallationAddressBuildingAndStreet2: (state, action) => {
      state.installationAddress.buildingAndStreet2 = action.payload;
    },
    setInstallationTownOrCity: (state, action) => {
      state.installationAddress.townOrCity = action.payload;
    },
    setInstallationCounty: (state, action) => {
      state.installationAddress.county = action.payload;
    },
    setInstallationPostCode: (state, action) => {
      state.installationAddress.postCode = action.payload;
    },
    setInstallerDetails: (state, action) => {
      state.installerDetails = action.payload;
    },
    setNumberOfChargepointsInstalled: (state, action) => {
      state.numberOfChargepointsInstalled = action.payload;
    },
    setNumberOfChargepointsSocketsInstalled: (state, action) => {
      state.numberOfChargepointsSocketsInstalled = action.payload;
    },
    setLandlordOrPmcPrivateParking: (state, action) => {
      state.landlordOrPmcPrivateParking = action.payload;
    },
    setGrantTypeSelected: (state, action) => {
      state.grantTypeSelected = action.payload;
    },
    setAvailableGrants: (state, action) => {
      state.currentlyAvailableGrants = action.payload;
    },
    setUnavailableGrants: (state, action) => {
      state.currentlyUnavailableGrants = action.payload;
    },
    setSelectedGrantValue: (state, action) => {
      state.grantTypeValue = action.payload;
    },
    setApplicantAnnualQuota: (state, action) => {
      state.applicantAnnualQuota = action.payload;
    },
    setApplicationDocumentId: (state, action) => {
      state.applicationDocumentId = action.payload;
    },
    setParkingTypeSelected: (state, action) => {
      state.parkingTypesSelected = action.payload;
    },
  },
});

export const {
  setBuildingTypeSelected,
  setInstallerOzevNumber,
  setStaffFleetChargepoints,
  setPropertyTypeSelected,
  setInstallerDetails,
  setInstallationAddressBuildingAndStreet,
  setInstallationAddressBuildingAndStreet2,
  setInstallationTownOrCity,
  setInstallationCounty,
  setInstallationPostCode,
  setNumberOfChargepointsInstalled,
  setNumberOfChargepointsSocketsInstalled,
  setLandlordOrPmcPrivateParking,
  setGrantTypeSelected,
  setAvailableGrants,
  setUnavailableGrants,
  setSelectedGrantValue,
  setApplicantAnnualQuota,
  setApplicationDocumentId,
  setSubcontractorId,
  setSubcontractorCompanyName,
  setSubcontractorEmail,
  setSubcontractorFirstName,
  setSubcontractorLastName,
  setParkingTypeSelected,
} = applicationJourneySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.registrationJourney.accountTypeSelected)`
export const getSubcontractorId = (state) =>
  state.applicationJourney.subcontractor_id;
export const getSubcontractorCompanyName = (state) =>
  state.applicationJourney.subcontractor_company_name_upper;

export const getSubcontractorFirstName = (state) =>
  state.applicationJourney.subcontractor_first_name;

export const getSubcontractorLastName = (state) =>
  state.applicationJourney.subcontractor_last_name;

export const getSubcontractorEmail = (state) =>
  state.applicationJourney.subcontractor_contact_email;

export const getBuildingTypeSelected = (state) =>
  state.applicationJourney.buildingTypeSelected;

export const getInstallerOzevNumber = (state) =>
  state.applicationJourney.installerOzevNumber;

export const getStaffFleetChargepoints = (state) =>
  state.applicationJourney.staffFleetChargepoints;

export const getPropertyTypeSelected = (state) =>
  state.applicationJourney.propertyTypeSelected;

export const getBuildingAndStreet = (state) =>
  state.applicationJourney.installationAddress.buildingAndStreet;

export const getBuildingAndStreet2 = (state) =>
  state.applicationJourney.installationAddress.buildingAndStreet2;

export const getTownOrCity = (state) =>
  state.applicationJourney.installationAddress.townOrCity;

export const getCounty = (state) =>
  state.applicationJourney.installationAddress.county;

export const getPostCode = (state) =>
  state.applicationJourney.installationAddress.postCode;

export const getInstallerDetails = (state) =>
  state.applicationJourney.installerDetails;

export const getNumberOfChargepointsInstalled = (state) =>
  state.applicationJourney.numberOfChargepointsInstalled;

export const getNumberOfChargepointsSocketsInstalled = (state) =>
  state.applicationJourney.numberOfChargepointsSocketsInstalled;

export const getUserType = (state) => state.applicationJourney.userType;

export const getLandlordOrPmcPrivateParking = (state) =>
  state.applicationJourney.landlordOrPmcPrivateParking;

export const getGrantTypeSelected = (state) =>
  state.applicationJourney.grantTypeSelected;

export const getAvailableGrants = (state) =>
  state.applicationJourney.currentlyAvailableGrants;

export const getUnavailableGrants = (state) =>
  state.applicationJourney.currentlyUnavailableGrants;

export const getSelectedGrantValue = (state) =>
  state.applicationJourney.grantTypeValue;

export const getApplicantAnnualQuota = (state) =>
  state.applicationJourney.applicantAnnualQuota;

export const getApplicationDocumentId = (state) =>
  state.applicationJourney.applicationDocumentId;

export const getParkingTypesSelected = (state) =>
  state.applicationJourney.parkingTypesSelected;

export default applicationJourneySlice.reducer;
