import React from 'react';
import { Button, SummaryList } from 'govuk-react-jsx';
import { useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { getAccount } from '../../firebase';
import { getExpiredClaim } from '../../features/dashboard/dashboardSlice';
import convertDateToDisplay from '../../utils/convertDateToDisplay';
import ClaimStatusClassName from '../common/ClaimStatus';

const CheckCustomerApplication = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const claim = useSelector(getExpiredClaim);

  const handleConfirmClaim = () => {
    getAccount()
      .then((response) => {
        const { data } = response;
        if (data?.account_type === 'Property Management Company') {
          window.location.assign('/dashboardHub/dashboard-pmc');
        } else if (data?.account_type === 'Landlord') {
          window.location.assign('/dashboardHub/dashboard');
        } else if (data?.account_type === 'Installer') {
          window.location.assign('/installer/claim-grant/installer-dashboard');
        } else if (data?.account_type === 'Small to medium enterprise') {
          window.location.assign('/sme-app/dashboard2');
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.debug('Get Account error:', err.message);
        throw new Error(err);
      });
  };

  const address = (
    <>
      <section>{`${claim?.installation_address_line_1}`}</section>
      <section>{`${claim?.installation_address_line_2}`}</section>
      <section>{`${claim?.installation_address_city}`}</section>
      <section>{`${claim?.installation_address_county}`}</section>
      <section>{`${claim?.installation_address_postcode}`}</section>
    </>
  );

  return (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'dashboardHub.ViewExiredClaim.s1',
          'Your application has expired'
        )}
      </h1>
      <h2 className="govuk-heading-m">
        {copyProvider.getCopy(
          'dashboardHub.ViewExiredClaim.s2',
          'Application details'
        )}
      </h2>
      <SummaryList
        className="govuk-!-margin-bottom-7"
        rows={[
          {
            key: {
              children: copyProvider.getCopy(
                'dashboardHub.ViewExiredClaim.s3',
                'Grant type'
              ),
            },
            value: {
              children: claim && claim.grant_type_description,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'dashboardHub.ViewExiredClaim.s4',
                'Reference number'
              ),
            },
            value: {
              children: claim && claim.claim_id,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'dashboardHub.ViewExiredClaim.s5',
                "Customer's name"
              ),
            },
            value: {
              children:
                claim &&
                (claim?.landlord_account_name || claim?.sme_account_name),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'dashboardHub.ViewExiredClaim.s6',
                'Installation address'
              ),
            },
            value: {
              children: address,
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'dashboardHub.ViewExiredClaim.s7',
                'Date the application was submitted'
              ),
            },
            value: {
              children:
                claim && convertDateToDisplay(claim.application_submitted_date),
            },
          },
          {
            key: {
              children: copyProvider.getCopy(
                'dashboardHub.ViewExiredClaim.s8',
                'Application status'
              ),
            },
            value: {
              children:
                claim &&
                ClaimStatusClassName(claim.claim_status.toLocaleUpperCase()),
            },
          },
        ]}
      />
      <Button className="govuk-button" onClick={handleConfirmClaim}>
        {copyProvider.getCopy(
          'dashboardHub.ViewExiredClaim.s9',
          'Manage your applications'
        )}
      </Button>
    </>
  );
};

export default CheckCustomerApplication;
