// eslint-disable-next-line
export const checkValidEmail = /^.*[@].*[.].*$/;
export const checkValidName = /^[a-zA-Z .'-]+$/;
export const checkValidRoleName = /^[a-zA-Z ]+$/;
export const checkValidNumberWithZero = /^[0-9]{11}$/;
export const checkValidNumberWithoutZero = /^[1-9]{1}[0-9]{9}$/;
export const checkValidPassword =
  /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
export const checkValidCompanyRegistration = /^[a-zA-Z0-9]{8}$/;
export const checkValidBusinessVAT = /^[a-zA-z0-9]{9}$/;
export const checkValidOzevNumber = /^[0-9]{3,5}$/;
export const checkValidBuildingAndStreet = /^[a-zA-Z0-9 ()!&-]{5,50}$/;
export const checkValidTownOrCity = /^[a-zA-Z0-9 ()!&-]{5,50}$/;
export const checkValidCounty = /^[a-zA-Z &()]{5,50}$/;
export const checkValidPostcode =
  /^(?:[a-zA-Z0-9]{5,7}|(?=.{6,8}$)([a-zA-Z0-9]{1,}[ ]{1}[a-zA-Z0-9]{1,}))$/;
export const checkValidSubcontractorOzevNumber = /^[0-9]{3,5}$/;
export const checkValidInstallationCost = /^\d+(\.\d{0,2})?$/;
export const checkValidChargepointsNumber = /^[0-9]{1,}$/;
export const checkValidInstallerNumber = /^[0-9]{3,5}$/;
export const checkLettersAndNumbersOnly = /^[a-zA-Z0-9]*$/;
export const checkSearchTerm = /^[a-zA-Z0-9- ()]{3,}$/;
