/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dateOfGrantApplication: new Date(2021, 10, 1),
  buildingTypeSelected: 'Individual flat or apartment building',
  propertyTypeSelected: 'Residential',
  customerName: 'Sherborne Properties',
  grantType: 'LANDLORD_RESIDENTIAL_GRANT',
  installationAddress: {
    addressLine1: 'Flat 2, 14 Royal Road',
    addressLine2: '',
    townOrCity: 'London',
    county: '',
    postCode: 'EC1R 1XS',
    privateParking: 'Yes',
  },
  installerName: 'Electric City Installers',
  ozevInstallerNum: 1005,
  subcontractorDetails: {
    subcontractor_number: '1234',
    subcontractor_name: 'Angus Young Installations',
    registered_office_address: {
      address_line_1: 'Westside Tower 7 City Road',
      region: 'London',
      country: 'England',
      postal_code: 'WS16 NWR',
    },
  },
  socketAllocationPage: '',
  claim_status: '',
  manufacturerModels: {
    residential: null,
    commercial: null,
  },

  // Dummy data
  numberOfChargepointsInstalled: '',
  numberOfChargepointsSocketsInstalled: '',
  subcontractorOZEVInstaller: '',
  subcontractorName: '',
  dayInstallationDate: '',
  monthInstallationDate: '',
  yearInstallationDate: '',
  dateOfCompletion: '',
  costOfInstallation: '',
  uploadedFile: '',

  // Dummy data for socket allocation pages
  numberOfCustomerNominatedChargepoints: '',

  // array of chargepoint detail objects
  chargepointDetails: [
    {
      manufacturer: '',
      model: '',
      totalInstalled: '', // this field is total number (of the specific manufacturer & model) installed
    },
  ],

  // chosen chergepoint in summary chargepoints page
  chosenChargePoint: '',
  applicationClaimId: '',

  // used on the summary screen
  individualChargepointDetails: [],

  totalGrantAmount: '',
  finalNumberOfSockets: '',
  allUploadedAdditionalFiles: '',
  chargepointUniqueSerialNum: '',
  registeredEmailAddress: '',
  // Dummy data for T478
  installerEmail: '',
  mockEmail: 'name@example.com',
};

export const installerJourneySlice = createSlice({
  name: 'installerJourney',
  initialState,
  reducers: {
    setPropertyTypeSelected: (state, action) => {
      state.propertyTypeSelected = action.payload;
    },
    setApplicationClaimId: (state, action) => {
      state.applicationClaimId = action.payload;
    },
    setBuildingTypeSelected: (state, action) => {
      state.buildingTypeSelected = action.payload;
    },
    setInstallationAddressBuildingAndStreet: (state, action) => {
      state.installationAddress.buildingAndStreet = action.payload;
    },
    setInstallationAddressBuildingAndStreet2: (state, action) => {
      state.installationAddress.buildingAndStreet2 = action.payload;
    },
    setInstallationTownOrCity: (state, action) => {
      state.installationAddress.townOrCity = action.payload;
    },
    setInstallationCounty: (state, action) => {
      state.installationAddress.county = action.payload;
    },
    setInstallationPostCode: (state, action) => {
      state.installationAddress.postCode = action.payload;
    },
    setInstallationPrivateParking: (state, action) => {
      state.installationAddress.privateParking = action.payload;
    },
    setInstallerDetails: (state, action) => {
      state.subcontractorDetails = action.payload;
    },
    setDateOfGrantApplication: (state, action) => {
      state.dateOfGrantApplication = action.payload;
    },
    setSubcontractorOzevNumber: (state, action) => {
      state.subcontractorOZEVInstaller = action.payload;
    },
    setSubcontractorDetails: (state, action) => {
      state.subcontractorDetails = action.payload;
    },
    setWhoCompletedInstallation: (state, action) => {
      state.whoCompletedInstallation = action.payload;
    },
    setDayInstallationDate: (state, action) => {
      state.dayInstallationDate = action.payload;
    },
    setMonthInstallationDate: (state, action) => {
      state.monthInstallationDate = action.payload;
    },
    setYearInstallationDate: (state, action) => {
      state.yearInstallationDate = action.payload;
    },
    setDateOfCompletion: (state, action) => {
      state.dateOfCompletion = action.payload;
    },
    setSocketAllocationPage: (state, action) => {
      state.socketAllocationPage = action.payload;
    },
    setCostOfInstallation: (state, action) => {
      state.costOfInstallation = action.payload;
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile = action.payload;
    },
    setNumberOfChargepointsInstalled: (state, action) => {
      state.numberOfChargepointsInstalled = action.payload;
    },
    setNumberOfChargepointsSocketsInstalled: (state, action) => {
      state.numberOfChargepointsSocketsInstalled = action.payload;
    },
    setNumberOfCustomerNominatedChargepoints: (state, action) => {
      state.numberOfCustomerNominatedChargepoints = action.payload;
    },
    setChargepointDetails: (state, action) => {
      state.chargepointDetails = action.payload;
    },
    setChosenChargePoint: (state, action) => {
      state.chosenChargePoint = action.payload;
    },
    setIndividualChargepointDetails: (state, action) => {
      state.individualChargepointDetails = action.payload;
    },
    updateChargepointDetails: (state, action) => {
      state.individualChargepointDetails =
        state.individualChargepointDetails.map((item) => {
          if (item.uid === action.payload.uid) {
            return action.payload;
          }

          return item;
        });
    },
    addIndividualChargepointDetails: (state, action) => {
      state.individualChargepointDetails = [
        ...state.individualChargepointDetails,
        ...action.payload,
      ];
    },
    setTotalGrantAmount: (state, action) => {
      state.totalGrantAmount = action.payload;
    },
    setFinalNumberOfSockets: (state, action) => {
      state.finalNumberOfSockets = action.payload;
    },
    setChargepointUniqueSerialNum: (state, action) => {
      state.chargepointUniqueSerialNum = action.payload;
    },
    setAllUploadedAdditionalFiles: (state, action) => {
      state.allUploadedAdditionalFiles = action.payload;
    },
    setRegisteredEmailAddress: (state, action) => {
      state.registeredEmailAddress = action.payload;
    },
    setOzevInstallerNum: (state, action) => {
      state.ozevInstallerNum = action.payload;
    },
    setInstallerEmail: (state, action) => {
      state.installerEmail = action.payload;
    },
    setInstallerDeclarationAccepted: (state, action) => {
      state.installerDeclarationAccepted = action.payload;
    },
    setClaimStatus: (state, action) => {
      state.claim_status = action.payload;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload;
    },
    setClaim: (state, action) => {
      const claim = action.payload;

      state.installationAddress = {
        addressLine1: claim.installation_address_line_1,
        addressLine2: claim.installation_address_line_2,
        townOrCity: claim.installation_address_city,
        county: claim.installation_address_county,
        postCode: claim.installation_address_postcode,
        privateParking: claim.is_private_parking,
      };

      state.grantType = claim.grant_type;

      state.installerName = claim.installer_company_name;
      state.ozevInstallerNum = claim.installer_id;
      state.propertyTypeSelected = claim.building_type;
      state.buildingTypeSelected = claim.building_type;

      state.dateOfGrantApplication = new Date(
        claim.claim_submitted_on_ts !== undefined
          ? // eslint-disable-next-line no-underscore-dangle
            claim.claim_submitted_on_ts._seconds * 1000
          : null
      );

      state.buildingTypeSelected = claim.building_type;
      state.propertyTypeSelected = claim.building_type;
      state.customerName = claim.landlord_account_name;
      state.grantType = claim.grant_type;
      state.numberOfCustomerNominatedChargepoints =
        claim.number_of_chargepoints_applied;
      state.dateOfCompletion = new Date(
        claim.installation_completed_date !== undefined
          ? // eslint-disable-next-line no-underscore-dangle
            claim.installation_completed_date._seconds * 1000
          : null
      ).toDateString();

      if (claim.subcontractor_id) {
        state.whoCompletedInstallation = 'A subcontractor';
        state.subcontractorDetails.subcontractor_name =
          claim.subcontractor_company_name;
        state.subcontractorDetails.subcontractor_number =
          claim.subcontractor_id;
      }

      if (claim.chargepoints_details === undefined) {
        return;
      }

      state.individualChargepointDetails = claim.chargepoints_details.map(
        (chargepoint) => ({
          uid: chargepoint ? chargepoint.chargepoint_uid : '',
          manufacturer: chargepoint
            ? chargepoint.chargepoint_manufacturer
            : null,
          model: chargepoint ? chargepoint.chargepoint_model : '',
          socket: chargepoint ? chargepoint.number_of_sockets : '',
          serialNumber: chargepoint ? chargepoint.serial_number : '',
          photos: chargepoint
            ? chargepoint.evidence?.map((file) => ({
                fileName: file.image.split('/').pop(),
                image: file.image,
                uploadedTs: new Date(
                  // eslint-disable-next-line no-underscore-dangle
                  file?.uploaded_ts?._seconds === undefined
                    ? 0
                    : // eslint-disable-next-line no-underscore-dangle
                      file.uploaded_ts._seconds * 1000
                ),
              }))
            : [],
        })
      );
    },
    setManufacturers: (state, action) => {
      const { payload } = action;
      const { residential, manufacturers } = payload;

      const values = manufacturers.reduce(
        (acc, key) => Object.assign(acc, { [key]: [] }),
        {}
      );

      if (residential) {
        state.manufacturerModels.residential = values;
      } else {
        state.manufacturerModels.commercial = values;
      }
    },
    setModels: (state, action) => {
      const { payload } = action;
      const { manufacturer, models, residential } = payload;

      if (residential) {
        state.manufacturerModels.residential[manufacturer] = models;
      } else {
        state.manufacturerModels.commercial[manufacturer] = models;
      }
    },
  },
});

export const {
  setWhoCompletedInstallation,
  setSubcontractorOzevNumber,
  setSubcontractorDetails,
  setDateOfGrantApplication,
  setDayInstallationDate,
  setMonthInstallationDate,
  setYearInstallationDate,
  setDateOfCompletion,
  setSocketAllocationPage,
  setCostOfInstallation,
  setUploadedFile,
  setNumberOfCustomerNominatedChargepoints,
  setChargepointDetails,
  setChosenChargePoint,
  setIndividualChargepointDetails,
  addIndividualChargepointDetails,
  updateChargepointDetails,
  setTotalGrantAmount,
  setAdditionalChargepointManufacturer,
  setFinalNumberOfSockets,
  setAllUploadedAdditionalFiles,
  setChargepointUniqueSerialNum,
  setRegisteredEmailAddress,
  setOzevInstallerNum,
  setInstallerEmail,
  setInstallerDeclarationAccepted,
  setClaimStatus,
  setCustomerName,
  setClaim,
  setManufacturers,
  setModels,
  setApplicationClaimId,
  setNumberOfChargepointsInstalled,
  setNumberOfChargepointsSocketsInstalled,
} = installerJourneySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.registrationJourney.accountTypeSelected)`
export const getBuildingTypeSelected = (state) =>
  state.installerJourney.buildingTypeSelected;

export const getPropertyTypeSelected = (state) =>
  state.installerJourney.propertyTypeSelected;

export const getAddressLine1 = (state) =>
  state.installerJourney.installationAddress.addressLine1;

export const getAddressLine2 = (state) =>
  state.installerJourney.installationAddress.addressLine2;

export const getTownOrCity = (state) =>
  state.installerJourney.installationAddress.townOrCity;

export const getCounty = (state) =>
  state.installerJourney.installationAddress.county;

export const getPostCode = (state) =>
  state.installerJourney.installationAddress.postCode;

export const getPrivateParking = (state) =>
  state.installerJourney.installationAddress.privateParking;

export const getInstallerDetails = (state) =>
  state.installerJourney.subcontractorDetails;

export const getInstallerName = (state) => state.installerJourney.installerName;

export const getDateOfGrantApplication = (state) =>
  state.installerJourney.dateOfGrantApplication;

export const getNumberOfChargepointsInstalled = (state) =>
  state.installerJourney.numberOfChargepointsInstalled;

export const getNumberOfChargepointsSocketsInstalled = (state) =>
  state.installerJourney.numberOfChargepointsSocketsInstalled;

export const getWhoCompletedInstallation = (state) =>
  state.installerJourney.whoCompletedInstallation;

export const getSubcontractorOzevInstaller = (state) =>
  state.installerJourney.subcontractorOZEVInstaller;

export const getSubcontractorDetails = (state) =>
  state.installerJourney.subcontractorDetails;

export const getDayInstallationDate = (state) =>
  state.installerJourney.dayInstallationDate;

export const getMonthInstallationDate = (state) =>
  state.installerJourney.monthInstallationDate;

export const getYearInstallationDate = (state) =>
  state.installerJourney.yearInstallationDate;

export const getDateOfCompletion = (state) =>
  state.installerJourney.dateOfCompletion;

export const getLandlordNominatedChargepoints = (state) =>
  state.installerJourney.landlordNominatedChargepoints;

export const getSocketAllocationPage = (state) =>
  state.installerJourney.socketAllocationPage;

export const getCostOfInstallation = (state) =>
  state.installerJourney.costOfInstallation;

export const getUploadedFile = (state) => state.installerJourney.uploadedFile;

export const getNumberOfCustomerNominatedChargepoints = (state) =>
  state.installerJourney.numberOfCustomerNominatedChargepoints;

export const getChargepointDetails = (state) =>
  state.installerJourney.chargepointDetails;

export const getChosenChargePoint = (state) =>
  state.installerJourney.chosenChargePoint;

export const getIndividualChargepointDetails = (state) =>
  state.installerJourney.individualChargepointDetails;

export const getFinalNumberOfSockets = (state) =>
  state.installerJourney.finalNumberOfSockets;

export const getAllUploadedAdditionalFiles = (state) =>
  state.installerJourney.allUploadedAdditionalFiles;

export const getChargepointUniqueSerialNum = (state) =>
  state.installerJourney.chargepointUniqueSerialNum;

export const getTotalGrantAmount = (state) =>
  state.installerJourney.totalGrantAmount;

export const getRegisteredEmailAddress = (state) =>
  state.installerJourney.registeredEmailAddress;

export const getOzevInstallerNum = (state) =>
  state.installerJourney.ozevInstallerNum;

export const getInstallerEmail = (state) =>
  state.installerJourney.installerEmail;

export const getMockEmail = (state) => state.installerJourney.mockEmail;

export const getInstallerDeclarationAccepted = (state) =>
  state.installerJourney.installerDeclarationAccepted;

export const getClaimStatus = (state) => state.installerJourney.claim_status;

export const getCustomerName = (state) => state.installerJourney.customerName;

export const getGrantTypeSelected = (state) => state.installerJourney.grantType;

export const getManufacturerModels = (residential) => (state) =>
  residential
    ? state.installerJourney.manufacturerModels.residential
    : state.installerJourney.manufacturerModels.commercial;

export const getCommercialManufacturerModels = (state) =>
  state.installerJourney.manufacturerModels.commercial;

export const getApplicationClaimId = (state) =>
  state.installerJourney.applicationClaimId;

export default installerJourneySlice.reducer;
