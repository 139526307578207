import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SummaryList, Button, ErrorSummary } from 'govuk-react-jsx';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../../features/app/appSlice';
import { deleteEvidence, uploadResubmitEvidence } from '../../../firebase';
import {
  getResubmitClaims,
  setChargepointEvidence,
  setInvoiceEvidence,
  setResubmitClaim,
} from '../../../features/resubmit/resubmitJourneySlice';
import FileManager from '../../common/FileManager';
import { getClaimsData } from '../../../features/customer/customerJourneySlice';
import convertDateToDisplay from '../../../utils/convertDateToDisplay';
import { removeAllItem } from '../../../utils/array-lib';
import useTitle from '../../../hooks/useTitle';
import useGaStartEvent from '../../../hooks/useGaStartEvent';

const ResubmitClaim = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const claimsValue = useSelector(getClaimsData);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  const resubmitClaimsData = useSelector(getResubmitClaims);
  const [hasUploadError, setHasUploadError] = useState({});
  const [errorUploadParent, setUploadErrorParent] = useState(null);
  const [errorInvoice, setErrorInvoice] = useState(null);
  const [errorFileList, setErrorFileList] = useState([]);
  const [errorInvoiceList, setErrorInvoiceList] = useState([]);
  const claimData = claimsValue.filter((c) => c.document_id === id)[0];

  useTitle(
    'Installer.Resubmit.ResubmitClaim.s28',
    'Resubmit your claim - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  useGaStartEvent('ResubmitClaim', 'resubmit_claim_start');

  const resubmitClaim = resubmitClaimsData.filter(
    (claim) => claim.documentId === claimData.document_id
  )[0];

  const PHOTO_FILE_SIZE = 5 * 1024 * 1024;
  const DOC_FILE_SIZE = 2 * 1024 * 1024;
  const PHOTOS_TYPE = 'photos';
  const INVOICE_TYPE = 'documents';
  const DOCTYPE_CHARGEPOINT = 'chargepoints';
  const DOCTYPE_INVOICE = 'invoice';
  const FAR_UPLOAD_FILE_LIMIT = 2;
  const OTHER_UPLOAD_FILE_LIMIT = 20;

  const collectionName = {
    'Renters and flat owners': 'customers',
    'Residential landlord': 'application_claim',
    'Commercial landlord': 'application_claim',
  };

  const FLATS_AND_RENTERS = 'Renters and flat owners';

  const resubmissionDeadline = convertDateToDisplay(
    claimData.claim_submission_deadline_date
  );
  const initialSubmissionDate = convertDateToDisplay(
    claimData.claim_submitted_date
  );
  const reviewerComments = claimData?.cw_response_for_installer?.split('. ');

  const validFileTypes = [
    'image/png',
    'image/gif',
    'image/bmp',
    'image/jpeg',
    'image/jpg',
  ];

  const validDocTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'application/vnd.ms-excel',
    'application/msword',
    'application/pdf',
  ];

  const chargepointHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s12',
    'Your photos must be a JPG, JPEG, PNG, GIF or BMP file and each one can be up to 5MB in size.'
  );

  const displayHintsForChargepoints = () => {
    return <p key={1}>{chargepointHint}</p>;
  };

  const firstInvoiceHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s13',
    'You can only upload one file. If you sent your customer more than one invoice, add a final invoice that includes your total charges.'
  );

  const secondInvoiceHint = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s14',
    'The document needs to be in JPG, PNG, GIF, BMP, XLS, DOC or PDF formats.'
  );

  const headingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s1',
    'Resubmit your claim'
  );
  const firstParTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s2',
    'The claim you have submitted has been rejected.'
  );
  const linkTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s3',
    'View what you have submitted before.'
  );
  const pTxtFirstPart = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s4',
    'You have until '
  );
  const pTxtSecondPart = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s5',
    ' to submit evidence for approval. After this date we will decline your claim.'
  );
  const firstSubheadingTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s10',
    'What was wrong and how to fix it'
  );

  const btnTxt = copyProvider.getCopy(
    'Installer.Resubmit.ResubmitClaim.s11',
    'Continue'
  );

  const grantType = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s6',
        'Grant type'
      ),
    },
    value: {
      children: claimData.grant_type_long_description,
    },
  };

  const refNr = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s7',
        'Reference number'
      ),
    },
    value: {
      children: claimData.customerReferenceNumber,
    },
  };

  const customerName = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s8',
        'Customer name'
      ),
    },
    value: {
      children: `${claimData.firstName} ${claimData.lastName}`,
    },
  };

  const claimDateSubmitted = {
    key: {
      children: copyProvider.getCopy(
        'Installer.Resubmit.ResubmitClaim.s9',
        'Date you submitted this claim'
      ),
    },
    value: {
      children: initialSubmissionDate,
    },
  };

  const invalidInvoiceFileFormatError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s15',
      'The invoice must be in a JPG, PNG, GIF, BMP, XLS, DOC or PDF format'
    ),
  };

  const invalidChargepointFormatError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s16',
      'Your photo must be a JPG, JPEG, PNG, GIF or BMP file'
    ),
  };

  const invalidChargepointSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s17',
      'Your photo must be smaller than 5MB'
    ),
  };

  const invalidInvoiceSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s18',
      'The invoice file must be below 2MB'
    ),
  };

  const invalidInvoiceFormatAnsSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s19',
      'The invoice must be below 2MB and be in a JPG, PNG, GIF, BMP, XLS, DOC or PDF format'
    ),
  };

  const invalidChargepointFormatAnsSizeError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s20',
      'Your photo must be less than 5MB and in a JPG, PNG, GIF, BMP or PDF format'
    ),
  };

  const invalidFARMoreFilesError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s21',
      'You can only upload up to 2 chargepoint photos'
    ),
  };

  const invalidOtherMoreFilesError = {
    children: copyProvider.getCopy(
      'Installer.Resubmit.ResubmitClaim.s22',
      'You can only upload up to 20 chargepoint photos'
    ),
  };

  const handleContinueClick = () => {
    let errorMsgMoreFilesUploaded = false;

    if (
      (claimData.grant_type === FLATS_AND_RENTERS &&
        resubmitClaim?.chargepointEvidence.length > FAR_UPLOAD_FILE_LIMIT) ||
      (claimData.grant_type !== FLATS_AND_RENTERS &&
        resubmitClaim?.chargepointEvidence.length > OTHER_UPLOAD_FILE_LIMIT)
    ) {
      errorMsgMoreFilesUploaded = true;
    }

    if (errorMsgMoreFilesUploaded) {
      setHasUploadError({
        errorMsgMoreFilesUploaded,
      });
    } else {
      setHasUploadError(null);
      history.push(`/resubmit-journey/check-answers-resubmit/${id}`);
    }
  };

  const handleLinkClick = (claimDataParam) => {
    switch (claimDataParam.grant_type) {
      case 'Renters and flat owners':
        history.push(
          `/resubmit-journey/review-claim-EVHS/${claimData.document_id}`
        );
        break;
      case 'Residential landlord':
        history.push(
          `/resubmit-journey/review-claim-residential-landlords/${claimData.document_id}`
        );
        break;
      case 'Commercial landlord':
        history.push(
          `/resubmit-journey/review-claim-commercial-landlords/${claimData.document_id}`
        );
        break;
      case 'Car Parks':
      case 'Staff and fleet':
        history.push(
          `/resubmit-journey/review-claim-carparks/${claimData.document_id}`
        );
        break;
      default:
        break;
    }
  };

  const displayHintsInvoice = () => {
    return (
      <>
        <p key={1} className="govuk-hint">
          {firstInvoiceHint}
        </p>
        <p key={2} className="govuk-hint">
          {secondInvoiceHint}
        </p>
      </>
    );
  };

  const extractFileInfo = (fileData, filePath) =>
    fileData.map((file) => {
      return {
        fileName: file.fileName,
        filePath: `${filePath}/${file.fileName}`,
      };
    });

  const updateChargepointEvidence = async (results, service) => {
    const response = await uploadResubmitEvidence({
      uploadFiles: results,
      id,
      uploadType: PHOTOS_TYPE,
      updateCollectionName: collectionName[service],
    });

    const filesInfo = extractFileInfo(results, response.data.resubmitPath);
    const chargepointEvidence = [
      ...resubmitClaim.chargepointEvidence,
      ...filesInfo,
    ];

    dispatch(
      setChargepointEvidence({
        documentId: claimData.document_id,
        chargepointEvidence,
      })
    );
  };

  const deleteInvoiceEvidence = async (filePath, service) => {
    const filteredFiles = resubmitClaim.invoiceEvidence.filter((file) => {
      return file.filePath !== filePath;
    });

    if (filteredFiles.length <= 0) {
      document.getElementById('file-upload').value = '';
    }
    await deleteEvidence({
      id,
      filePath,
      updateCollectionName: collectionName[service],
      uploadType: INVOICE_TYPE,
    });
  };

  const updateInvoiceEvidence = async (results, service) => {
    if (
      resubmitClaim &&
      resubmitClaim.invoiceEvidence &&
      resubmitClaim.invoiceEvidence.length > 0
    ) {
      await deleteInvoiceEvidence(
        resubmitClaim.invoiceEvidence[0].filePath,
        claimData.grant_type
      );
    }

    const response = await uploadResubmitEvidence({
      uploadFiles: results,
      id,
      uploadType: INVOICE_TYPE,
      updateCollectionName: collectionName[service],
    });
    const filesInfo = extractFileInfo(results, response.data.resubmitPath);

    dispatch(
      setInvoiceEvidence({
        documentId: claimData.document_id,
        invoiceEvidence: filesInfo,
      })
    );
  };

  const handleUpload = async (results, documentType, service) => {
    switch (documentType) {
      case DOCTYPE_CHARGEPOINT:
        updateChargepointEvidence(results, service);
        break;
      case DOCTYPE_INVOICE:
        updateInvoiceEvidence(results, service);
        break;
      default:
        break;
    }
  };

  const deleteChargepointEvidence = async (filePath, service) => {
    const filteredFiles = resubmitClaim.chargepointEvidence.filter((file) => {
      return file.filePath !== filePath;
    });

    if (filteredFiles.length <= 0) {
      document.getElementById('file-upload').value = '';
    }
    await deleteEvidence({
      id,
      filePath,
      updateCollectionName: collectionName[service],
      uploadType: PHOTOS_TYPE,
    });

    dispatch(
      setChargepointEvidence({
        documentId: claimData.document_id,
        chargepointEvidence: filteredFiles,
      })
    );
  };

  const handleDelete = async (event, _index, filePath) => {
    event.preventDefault();
    await deleteChargepointEvidence(filePath, claimData.grant_type);
  };

  const checkIfAnyFilesUploaded = () => {
    if (resubmitClaim) {
      return (
        resubmitClaim.chargepointEvidence.length <= 0 &&
        resubmitClaim.invoiceEvidence.length <= 0
      );
    }
    return false;
  };

  useEffect(() => {
    const errorListFileArr = [];
    const errorListInvoiceArr = [];
    if (errorUploadParent !== null && errorUploadParent !== undefined) {
      const errMessages = [
        invalidChargepointFormatError.children,
        invalidChargepointSizeError.children,
        invalidFARMoreFilesError.children,
        invalidOtherMoreFilesError.children,
        invalidChargepointFormatAnsSizeError.children,
      ];
      removeAllItem(errorFileList, errMessages);
      if (errorUploadParent !== '') {
        setErrorFileList([
          ...errorFileList,
          {
            ...errorUploadParent,
            href: '#file-upload',
          },
        ]);
      }
    } else {
      setErrorFileList([...errorFileList, ...errorListFileArr]);
    }
    if (errorInvoice !== null && errorInvoice !== undefined) {
      const errMessages = [
        invalidInvoiceFileFormatError.children,
        invalidInvoiceSizeError.children,
        invalidInvoiceFormatAnsSizeError.children,
      ];
      removeAllItem(errorInvoiceList, errMessages);
      if (errorInvoice !== '') {
        setErrorInvoiceList([
          ...errorInvoiceList,
          {
            ...errorInvoice,
            href: '#invoice-upload',
          },
        ]);
      }
    } else {
      setErrorInvoiceList([...errorInvoiceList, ...errorListInvoiceArr]);
    }
  }, [errorUploadParent, errorInvoice]);

  useEffect(() => {
    if (
      !resubmitClaimsData.some((r) => r.documentId === claimData.document_id)
    ) {
      dispatch(
        setResubmitClaim([
          ...resubmitClaimsData,
          {
            documentId: claimData.document_id,
            chargepointEvidence: [],
            invoiceEvidence: [],
          },
        ])
      );
    }
  }, []);

  return (
    <>
      {(errorFileList?.length > 0 || errorInvoiceList?.length > 0) && (
        <ErrorSummary
          errorList={[...errorFileList, ...errorInvoiceList]}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">{headingTxt}</h1>
      <p className="govuk-body">
        {firstParTxt}
        <br />
        <Link to={{}} onClick={() => handleLinkClick(claimData)}>
          {linkTxt}
        </Link>
      </p>
      <p className="govuk-body">
        {`${pTxtFirstPart} ${resubmissionDeadline} ${pTxtSecondPart}`}
      </p>

      <SummaryList
        rows={[grantType, refNr, customerName, claimDateSubmitted]}
      />
      <h2 className="govuk-heading-m govuk-!-margin-top-8">
        {firstSubheadingTxt}
      </h2>
      <ul className="govuk-list govuk-list--bullet">
        {reviewerComments &&
          reviewerComments.map((comment) => {
            return <li key={comment}>{comment}</li>;
          })}
      </ul>

      <h2 className="govuk-heading-m govuk-!-margin-top-9">
        {copyProvider.getCopy(
          'Installer.Resubmit.ResubmitClaim.s23',
          'Upload your new evidence'
        )}
      </h2>
      <label htmlFor="photo-upload">
        <h3 className="govuk-heading-s">
          {copyProvider.getCopy(
            'Installer.Resubmit.ResubmitClaim.s26',
            'Upload chargepoint photos'
          )}
        </h3>
      </label>
      <div className="govuk-form-group govuk-!-margin-top-7">
        <FileManager
          errorMessage={{
            invalidFileFormatError: invalidChargepointFormatError,
            invalidSizeError: invalidChargepointSizeError,
            invalidMoreFilesError:
              claimData.grant_type === FLATS_AND_RENTERS
                ? invalidFARMoreFilesError
                : invalidOtherMoreFilesError,
            invalidSizeAndFormat: invalidChargepointFormatAnsSizeError,
          }}
          label={{
            children: copyProvider.getCopy(
              'Installer.Resubmit.ResubmitClaim.s24',
              'Add chargepoint photos'
            ),
          }}
          hint={[displayHintsForChargepoints()]}
          name="file-upload"
          id="photo-upload"
          validFileTypes={validFileTypes}
          fileSize={PHOTO_FILE_SIZE}
          accept=".jpg, .png, .gif, .bmp, .jpeg"
          hasMoreFilesUploadError={
            (hasUploadError && hasUploadError.errorMsgMoreFilesUploaded) ||
            false
          }
          handleUpload={(result) =>
            handleUpload(result, DOCTYPE_CHARGEPOINT, claimData.grant_type)
          }
          handleDelete={handleDelete}
          evidence={resubmitClaim?.chargepointEvidence}
          setUploadErrorParent={setUploadErrorParent}
        />
      </div>
      <label htmlFor="invoice-upload">
        <h3 className="govuk-heading-s">
          {copyProvider.getCopy(
            'Installer.Resubmit.ResubmitClaim.s27',
            'Upload invoice'
          )}
        </h3>
      </label>
      <div className="govuk-form-group govuk-!-margin-top-9">
        {displayHintsInvoice()}
        <FileManager
          errorMessage={{
            invalidFileFormatError: invalidInvoiceFileFormatError,
            invalidSizeError: invalidInvoiceSizeError,
            invalidSizeAndFormat: invalidInvoiceFormatAnsSizeError,
          }}
          label={{
            children: copyProvider.getCopy(
              'Installer.Resubmit.ResubmitClaim.s25',
              'Add invoice'
            ),
          }}
          name="invoice-upload"
          id="invoice-upload"
          validFileTypes={validDocTypes}
          fileSize={DOC_FILE_SIZE}
          accept=".jpg, .jpeg, .png, .gif, .bmp, .pdf, .xls, .doc"
          handleUpload={(result) =>
            handleUpload(result, DOCTYPE_INVOICE, claimData.grant_type)
          }
          handleDelete={handleDelete}
          evidence={resubmitClaim?.invoiceEvidence}
          isInvoice
          setUploadErrorParent={setErrorInvoice}
        />
      </div>
      <Button
        disabled={checkIfAnyFilesUploaded()}
        onClick={handleContinueClick}
      >
        {btnTxt}
      </Button>
    </>
  );
};

export default ResubmitClaim;
