import React from 'react';
import { SummaryList } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import fileDownload from 'js-file-download';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import { downloadFiles } from '../../firebase';

const FinancialDetails = ({ claimData }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const proofCustomerInvoiceFileName =
    claimData.invoice_evidence.length > 0
      ? claimData.invoice_evidence[0].invoice_id
      : '';

  const invoiceEvidenceDownload =
    claimData.invoice_evidence.length > 0
      ? claimData.invoice_evidence.map((e) => {
          return {
            imageName: e.invoice_id,
            imagePath: e.invoice_link,
          };
        })
      : [];

  const totalGrantAmount = () => {
    if (claimData.grant_type !== 'Renters and flat owners') {
      return copyProvider.getCopy(
        'Installer.Resubmit.FinancialDetails.s5',
        'Total grant amount to be received'
      );
    }
    return '';
  };

  const handleClick = async (data) => {
    const response = await downloadFiles({
      download: data,
    });

    response.data.filesURL.forEach((file) => {
      axios
        .get(file.imageURL, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, file.imageName);
        })
        .catch(() => {
          // TODO
        });
    });
  };
  return (
    <>
      <h1 className="govuk-heading-m govuk-!-margin-top-5">
        {copyProvider.getCopy(
          'Installer.Resubmit.FinancialDetails.s1',
          'Financial details'
        )}
      </h1>
      <SummaryList
        className="govuk-heading-m govuk-!-margin-bottom-9"
        rows={[
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.FinancialDetails.s2',
                'Total cost of installation'
              ),
            },
            value: {
              children: `£${Number(claimData.total_installation_cost).toFixed(
                2
              )}`,
            },
          },
          // Proof of customer invoice:
          {
            key: {
              children: copyProvider.getCopy(
                'Installer.Resubmit.FinancialDetails.s3',
                'Customer invoice'
              ),
            },
            value: {
              children: (
                <div>
                  <section>{`${proofCustomerInvoiceFileName}`}</section>
                  <Link
                    to={{}}
                    onClick={() => handleClick(invoiceEvidenceDownload)}
                  >
                    {copyProvider.getCopy(
                      'Installer.Resubmit.FinancialDetails.s4',
                      'Download invoice'
                    )}
                  </Link>
                </div>
              ),
            },
          },
          totalGrantAmount &&
            claimData.total_grant_amount && {
              key: {
                children: totalGrantAmount(),
              },
              value: {
                children: `£${Number(claimData.total_grant_amount).toFixed(2)}`,
              },
            },
        ]}
      />
    </>
  );
};
FinancialDetails.propTypes = {
  claimData: PropTypes.object.isRequired,
};
export default FinancialDetails;
