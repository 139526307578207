/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Input, Button, Table, Tag, ErrorSummary } from 'govuk-react-jsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import {
  setDocumentId,
  getApplicationCount,
  getClientCount,
  getDashboardUserType,
  setExpiredClaim,
} from '../../features/dashboard/dashboardSlice';

import { getDashboardData } from '../../firebase';
import useTitle from '../../hooks/useTitle';
import displayMenu from '../../hooks/displayMenu';

const Dashboard = () => {
  const dispatch = useDispatch();
  const applicationCountRedux = useSelector(getApplicationCount);
  const clientCountRedux = useSelector(getClientCount);
  const dashboardUserTypeRedux = useSelector(getDashboardUserType);
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;
  useTitle(
    'dashboardHub.Dashboard.s29',
    'You submitted your application - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const [tableRows, setTableRows] = useState([]);
  const [tableLandlordRows, setTableLandlordRows] = useState([]);

  const [searchStatus, setSearchStatus] = useState({
    searched: false,
    searchTerm: '',
    resultCount: 0,
    allData: null,
    errorMessage: '',
  });
  const [errorList, setErrorList] = useState([]);

  const checkTagLabel = (claimStatus) => {
    switch (claimStatus) {
      case 'Sent to Installer':
        return <Tag className="govuk-tag--yellow">{claimStatus}</Tag>;
      case 'Expiring Soon':
        return <Tag className="govuk-tag--orange">{claimStatus}</Tag>;
      case 'Awaiting Customer Review':
        return <Tag className="govuk-tag--red">{claimStatus}</Tag>;
      case 'Submitted by Installer':
        return <Tag className="govuk-tag--blue">{claimStatus}</Tag>;
      case 'Completed':
        return <Tag className="govuk-tag--green">{claimStatus}</Tag>;
      case 'Declined':
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
      case 'Expired':
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
      default:
        return <Tag className="govuk-tag--grey">{claimStatus}</Tag>;
    }
  };
  const handleClick = (e) => {
    if (e.claim_status === 'Expired') {
      dispatch(setExpiredClaim(e));
      window.location.assign(`${e.link}/${e.document_id}`);
      return;
    }
    if (e.isCarParkClaim) {
      window.location.assign(`/car-parks${e.link}/${e.document_id}`);
      return;
    }
    dispatch(setDocumentId(e.document_id));
    window.location.assign(`/evcg${e.link}`);
  };

  const handleLandlordSearch = () => {
    const { searchTerm, searched, allData } = searchStatus;
    if (searchTerm.length < 3) {
      setSearchStatus({
        ...searchStatus,
        errorMessage: copyProvider.getCopy(
          'dashboardHub.Dashboard.s27',
          'Enter 3 or more characters'
        ),
      });
      setErrorList([
        {
          children: copyProvider.getCopy(
            'dashboardHub.Dashboard.s27',
            'Enter 3 or more characters'
          ),
          href: '#input-with-hint-text',
        },
      ]);

      return;
    }

    if (!/^[a-z0-9 -]*$/i.test(searchTerm)) {
      setSearchStatus({
        ...searchStatus,
        errorMessage: copyProvider.getCopy(
          'dashboardHub.Dashboard.s28',
          'Enter a valid character'
        ),
      });
      setErrorList([
        {
          children: copyProvider.getCopy(
            'dashboardHub.Dashboard.s27',
            'Enter 3 or more characters'
          ),
          href: '#input-with-hint-text',
        },
      ]);

      return;
    }

    const all = searched ? allData : tableLandlordRows;
    const searchRows = all.filter((entry) => {
      const { cells } = entry;
      // cell 0 is reference and cell 2 is postcode
      return (
        cells[0].children
          .toLowerCase()
          .startsWith(searchTerm.toLocaleLowerCase()) ||
        cells[2].children
          .toLowerCase()
          .startsWith(searchTerm.toLocaleLowerCase())
      );
    });
    setSearchStatus({
      ...searchStatus,
      searched: true,
      allData: all,
      resultCount: searchRows.length,
      errorMessage: '',
    });
    setErrorList([]);

    if (searchRows.length > 0) {
      setTableLandlordRows(searchRows);
    }
  };

  const handleLandlordSearchInputChange = (e) => {
    const { value } = e.target;

    setSearchStatus({
      ...searchStatus,
      searchTerm: value,
    });
  };

  const clearLandlordSearch = (e) => {
    e.preventDefault();
    setTableLandlordRows(searchStatus.allData);
    setSearchStatus({
      searched: false,
      searchTerm: '',
      resultCount: 0,
      allData: null,
      errorMessage: '',
    });
    setErrorList([]);
  };

  const capitalizeFirstLetter = (word) => {
    return word !== undefined && word
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : '';
  };

  displayMenu();

  useEffect(() => {
    getDashboardData()
      .then((response) => {
        const { data } = response;
        const tableRowsLocal = [];
        data.arr.map((eachData) => {
          return tableRowsLocal.push({
            cells: [
              { children: eachData.claim_id },
              { children: capitalizeFirstLetter(eachData.grant_type_display) },
              { children: eachData.installation_address_postcode },
              {
                children: checkTagLabel(eachData.claim_status),
              },
              {
                children: (
                  <Link to={{}} onClick={() => handleClick(eachData)}>
                    {eachData.action}
                  </Link>
                ),
              },
            ],
          });
        });

        setTableRows(tableRowsLocal);
        const tableLandlordRowsLocal = [];
        data.arr.map((eachData) => {
          return tableLandlordRowsLocal.push({
            cells: [
              { children: eachData.claim_id },
              { children: capitalizeFirstLetter(eachData.grant_type_display) },
              { children: eachData.installation_address_postcode },
              {
                children: checkTagLabel(eachData.claim_status),
              },
              {
                children: (
                  <Link to={{}} onClick={() => handleClick(eachData)}>
                    {eachData.action}
                  </Link>
                ),
              },
            ],
          });
        });
        setTableLandlordRows(tableLandlordRowsLocal);
      })
      .catch(() => {});
  }, []);

  // NB: The below section was part of T46 (Pmc without clients)
  const pmcWithoutClients = (
    <>
      <h1 className="govuk-heading-l">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s6',
          'Manage your applications'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s3',
          'You have not started any grant applications yet.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s7',
          'You will need to register a new client before you can submit an application.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s5',
          'You can also update your account details and add new users within the your account section, which can be found in the navigation at the top of this service'
        )}
      </p>
      <Button
        className="govuk-button govuk-!-margin-top-3"
        onClick={() =>
          window.location.assign('/evcg/landlordReg/property-type')
        }
      >
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s8',
          'Register a new client'
        )}
      </Button>
    </>
  );

  // NB: The below section was part of T47 (Pmc with clients)
  const pmcWithClients = (
    <>
      <h1 className="govuk-heading-l">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s6',
          'Manage your applications'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s3',
          'You have not started any grant applications yet.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s4',
          'Once you submit an application it will be shown on your dashboard and you will be able to track its progress.'
        )}
      </p>
      <Button
        onClick={() => window.location.assign('/evcg/client-section')}
        className="govuk-!-margin-top-5"
      >
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s2',
          'Start a new application'
        )}
      </Button>
    </>
  );

  // NB: The code below was part of T477 (Landlord landing page)
  const landlordWithoutApplications = (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s1',
          'Manage your applications'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s3',
          'You have not started any grant applications yet.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s4',
          'Once you submit an application, you will be able to see it and track its progress here.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s5',
          'You can view your account details in ‘Your account’ at the top of this page.'
        )}
      </p>
      <Button
        className="govuk-button govuk-!-margin-top-3"
        onClick={() =>
          window.location.assign('/evcg/landlordApp/select-grant-type')
        }
      >
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s2',
          'Start a new application'
        )}
      </Button>
    </>
  );

  const landlordTable = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summary',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s6',
          'Manage your applications'
        )}
      </h1>
      <Button
        onClick={() =>
          window.location.assign('/evcg/landlordApp/select-grant-type')
        }
      >
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s9',
          'Start a new application'
        )}
      </Button>
      <hr />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <h2 className="govuk-heading-m">
            <label htmlFor="input-with-hint-text">
              {copyProvider.getCopy(
                'dashboardHub.Dashboard.s10',
                'Find an application'
              )}
            </label>
          </h2>
          <Input
            hint={{
              children: copyProvider.getCopy(
                'dashboardHub.Dashboard.s19',
                'You can search by reference number or postcode'
              ),
            }}
            id="input-with-hint-text"
            name="test-name-2"
            type="text"
            onChange={handleLandlordSearchInputChange}
            value={searchStatus.searchTerm}
            errorMessage={
              searchStatus.errorMessage.length && {
                children: searchStatus.errorMessage,
              }
            }
          />
        </div>
        <div className="govuk-grid-column-one-third app-padding-top-dashboard">
          <Button onClick={handleLandlordSearch}>
            {copyProvider.getCopy('dashboardHub.Dashboard.s12', 'Search')}
          </Button>
        </div>
      </div>
      {searchStatus.searched && searchStatus.resultCount === 0 && (
        <>
          <h3 className="govuk-heading-s">
            {copyProvider.getCopy(
              'dashboardHub.Dashboard.s21',
              'There are no matching results'
            )}
          </h3>
          <p className="govuk-body">
            {copyProvider.getCopy(
              'dashboardHub.Dashboard.s22',
              'Improve your search results by:'
            )}
          </p>
          <ul className="govuk-list govuk-list--bullet">
            <li>
              {copyProvider.getCopy(
                'dashboardHub.Dashboard.s23',
                'double-checking your spelling'
              )}
            </li>
            <li>
              {copyProvider.getCopy(
                'dashboardHub.Dashboard.s24',
                'using fewer keywords'
              )}
            </li>
          </ul>
          <Link
            className="gov-link"
            style={{ fontWeight: 'normal' }}
            onClick={clearLandlordSearch}
          >
            {copyProvider.getCopy('dashboardHub.Dashboard.s20', 'Clear search')}
          </Link>
        </>
      )}
      {(!searchStatus.searched || searchStatus.resultCount > 0) && (
        <>
          {searchStatus.searched && searchStatus.resultCount > 0 && (
            <>
              <p className="govuk-heading-s">
                {searchStatus.resultCount}{' '}
                {searchStatus.resultCount > 1
                  ? copyProvider.getCopy(
                      'dashboardHub.Dashboard.s25',
                      'applications'
                    )
                  : copyProvider.getCopy(
                      'dashboardHub.Dashboard.s26',
                      'application'
                    )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  style={{ fontWeight: 'normal' }}
                  onClick={clearLandlordSearch}
                >
                  {copyProvider.getCopy(
                    'dashboardHub.Dashboard.s20',
                    'Clear search'
                  )}
                </Link>
              </p>
              <p />
              <hr />
            </>
          )}
          <Table
            head={[
              {
                children: copyProvider.getCopy(
                  'dashboardHub.Dashboard.s13',
                  'Reference number'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.Dashboard.s14',
                  'Grant type'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.Dashboard.s16',
                  'Postcode'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.Dashboard.s17',
                  'Claim status'
                ),
              },
              {
                children: copyProvider.getCopy(
                  'dashboardHub.Dashboard.s18',
                  'Action'
                ),
              },
            ]}
            rows={tableLandlordRows.map((row) => row)}
          />
        </>
      )}
    </>
  );

  const pmcTable = (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s6',
          'Manage your applications'
        )}
      </h1>
      <Button
        onClick={() =>
          window.location.assign('/evcg/landlordApp/select-grant-type')
        }
      >
        {copyProvider.getCopy(
          'dashboardHub.Dashboard.s9',
          'Start a new application'
        )}
      </Button>
      <hr />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-two-thirds">
          <h2 className="govuk-heading-m">
            <label htmlFor="input-with-hint-text">
              {copyProvider.getCopy(
                'dashboardHub.Dashboard.s10',
                'Find an application'
              )}
            </label>
          </h2>
          <Input
            hint={{
              children: copyProvider.getCopy(
                'dashboardHub.Dashboard.s11',
                'You can search by customer name, reference number or postcode'
              ),
            }}
            id="input-with-hint-text"
            name="test-name-2"
            type="text"
          />
        </div>
        <div className="govuk-grid-column-one-third app-padding-top-dashboard">
          <Button>
            {copyProvider.getCopy('dashboardHub.Dashboard.s12', 'Search')}
          </Button>
        </div>
      </div>
      <Table
        head={[
          {
            children: copyProvider.getCopy(
              'dashboardHub.Dashboard.s13',
              'Reference number'
            ),
          },
          {
            children: copyProvider.getCopy(
              'dashboardHub.Dashboard.s14',
              'Grant type'
            ),
          },
          {
            children: copyProvider.getCopy(
              'dashboardHub.Dashboard.s15',
              'Customer'
            ),
          },
          {
            children: copyProvider.getCopy(
              'dashboardHub.Dashboard.s16',
              'Postcode'
            ),
          },
          {
            children: copyProvider.getCopy(
              'dashboardHub.Dashboard.s17',
              'Claim status'
            ),
          },
          {
            children: copyProvider.getCopy(
              'dashboardHub.Dashboard.s18',
              'Action'
            ),
          },
        ]}
        rows={tableRows.map((row) => row)}
      />
    </>
  );

  const YourDashboard = (
    <div className="govuk-width-container">
      {dashboardUserTypeRedux === 'pmc' &&
        applicationCountRedux === 0 &&
        clientCountRedux === 0 &&
        pmcWithoutClients}

      {dashboardUserTypeRedux === 'pmc' &&
        applicationCountRedux === 0 &&
        clientCountRedux > 0 &&
        pmcWithClients}

      {dashboardUserTypeRedux === 'pmc' &&
        applicationCountRedux > 0 &&
        pmcTable}

      {dashboardUserTypeRedux === 'landlord' &&
        tableLandlordRows.length === 0 &&
        landlordWithoutApplications}

      {dashboardUserTypeRedux === 'landlord' &&
        tableLandlordRows.length > 0 &&
        landlordTable}
    </div>
  );

  return YourDashboard;
};

export default Dashboard;
