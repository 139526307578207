import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'govuk-react-jsx';

import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../../features/app/appSlice';

import displayMenu from '../../../hooks/displayMenu';

const InstallerDashboardFirstTime = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  const onButtonClick = () => {
    window.location.assign(
      '/evhs/installer/application-setup/customer-details'
    );
  };

  displayMenu();

  return (
    <>
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s1',
          'Manage your claims'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s2',
          'You have not started any claims yet.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s3',
          'Once you start a claim, you will be able to see it and track its progress here.'
        )}
      </p>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s4',
          "You can also update your account details and add new users in 'your account' at the top of this page."
        )}
      </p>
      <br />
      <br />
      <h1 className="govuk-heading-m">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s5',
          'Make a new claim for the Electric Vehicle Homecharge Scheme (EVHS)'
        )}
      </h1>
      <p className="govuk-body">
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s6',
          "Start a new claim by entering your customer's details so we can contact them."
        )}
      </p>
      <Button className="govuk-!-margin-top-5" onClick={onButtonClick}>
        {copyProvider.getCopy(
          'Installer.Dashboard.InstallerDashboardFirstTime.s7',
          'Add customer details'
        )}
      </Button>
    </>
  );
};

export default InstallerDashboardFirstTime;
